<template>
  <div id="ObraCadastro" class="py-3 px-0">
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>
      <v-btn
        v-if="store_Obra.acao == 'C'"
        @click="
          store_Obra.acao = 'E';
          obra_edicaoAux = {
            ...store_Obra.obra_edicao,
          };
        "
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-2 "
          >mdi-view-headline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span
        >
      </v-btn>
    </v-toolbar-title>

    <v-dialog
      v-model="store_Obra.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
    </v-dialog>

    <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-form v-model="valid" id="form" ref="form" class="mx-0 mt-3">
      <v-container class="mt-2 d-flex flex-column">
        <!-- 1ª Linha - Avatar / Nome / CNO -->
        <v-row no-gutter class="pt-0 mb-n14">
          <!-- Avatar da Obra -->
          <v-col sm="4" class="pt-2 px-2 justify-center">
            <div class="d-none d-sm-flex justify-center">
              <v-avatar
                rounded
                size="100px"
                v-ripple
                v-if="store_Obra.acao == 'C'"
                class="grey lighten-2"
              >
                <span
                  v-if="!store_Obra.obra_edicao.foto_obra"
                  color="#274ABB"
                  class="headline"
                >
                  {{ nomeIniciais(store_Obra.obra_edicao.nome_obra) }}
                </span>
                <img
                  v-if="store_Obra.obra_edicao.foto_obra"
                  :src="store_Obra.obra_edicao.foto_obra"
                  alt="avatar"
                />
              </v-avatar>

              <v-avatar
                rounded
                size="100px"
                v-ripple
                v-if="store_Obra.acao != 'C'"
                class="grey lighten-2"
              >
                <span
                  v-if="!store_Obra.obra_edicao.foto_obra"
                  v-on:click="dialog_crop = true"
                >
                  Clique p/ adicionar avatar
                </span>
                <img
                  v-if="store_Obra.obra_edicao.foto_obra"
                  v-on:click="dialog_crop = true"
                  :src="store_Obra.obra_edicao.foto_obra"
                  alt="avatar"
                />
              </v-avatar>
            </div>
          </v-col>

          <!-- Nome da Obra -->
          <v-col md="6">
            <v-text-field
              v-model="store_Obra.obra_edicao.nome_obra"
              :readonly="store_Obra.acao == 'C'"
              class="mb-0"
              loader-height="1"
              label="Nome da Obra"
              placeholder="Nome da Obra"
              background-color="#FFF"
              maxlength="100"
              light
              filled
              outlined
              dense
              autofocus
              :rules="nomeObraRules"
            ></v-text-field>
          </v-col>

          <!-- CNO -->
          <v-col md="2">
            <v-text-field
              v-model="store_Obra.obra_edicao.cno"
              :readonly="store_Obra.acao == 'C'"
              class="mb-0"
              loader-height="1"
              label="CNO"
              placeholder="Código Nacional de Obras"
              background-color="#FFF"
              maxlength="15"
              light
              filled
              outlined
              dense
              v-mask="MASK_CNO"
              :rules="cnoRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 2ª Linha - Área / Cliente -->
        <v-row no-gutter class="pt-0 mb-n10">
          <v-col cols="4"></v-col>
          <!-- Área -->
          <v-col md="2" class="pt-0">
            <v-text-field
              v-model="store_Obra.obra_edicao.area_obra"
              :readonly="store_Obra.acao == 'C'"
              class="mb-0"
              loader-height="1"
              label="Área"
              placeholder="Área"
              background-color="#FFF"
              maxlength="15"
              light
              filled
              outlined
              dense     
            ></v-text-field>
          </v-col>
          <!-- Cliente -->
          <v-col md="6" class="pt-0">
            <v-text-field
              v-model="store_Obra.obra_edicao.obra_cliente.pessoa_nome"
              readonly
              label="Cliente"
              placeholder="Nome do Cliente"
              class="mb-0"
              filled
              outlined
              dense
              background-color="#fff  "
              item-text="name"
            >
              <template v-slot:append-outer>
                  <v-icon
                  @click="store_Obra.obra_edicao.obra_cliente = {}, store_Obra.obra_edicao.cod_cliente = null"
                  :disabled="store_Obra.acao == 'C' "
                  class="input-conjuge mb-0"
                  color="primary">
                  mdi-delete
                  </v-icon>
                  <v-icon
                  @click="dialog_seleciona_cliente = true"
                  :disabled="store_Obra.acao == 'C'"
                  class="ml-2"
                  color="primary">
                  mdi-magnify-plus
                  </v-icon>
                </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- 3ª Linha - P.Inicio / P.Termino / Empreitada -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Data Previsão Inicio -->
          <v-col md="3"> 
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_previsao_inicio"
              :readonly="store_Obra.acao == 'C'"
              class="mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Data Previsão de Início"
              placeholder="Data Previsão de Início"
              light
              maxlength="10"
              filled
              outlined
              dense
            ></DatePicker>
          </v-col>
          <!-- Data Previsão Termino -->
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_previsao_termino"
              :readonly="store_Obra.acao == 'C'"
              class="mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Data Previsão de Término"
              placeholder="Data Previsão de Término"
              light
              maxlength="10"
              filled
              outlined
              dense
            ></DatePicker>
          </v-col>
          <!-- Empreitada -->
          <v-col md="6">
            <v-text-field
              v-model="store_Obra.obra_edicao.obra_empreitada.pessoa_nome"
              readonly
              label="Empreitada"
              placeholder="Empreitada"
              class="mb-0"
              filled
              outlined
              dense
              background-color="#fff  "
              item-text="name"
            >
              <template v-slot:append-outer>
                <v-icon
                @click="store_Obra.obra_edicao.obra_empreitada = {} , store_Obra.obra_edicao.cod_empreitada = null"
                :disabled="store_Obra.acao == 'C' "
                class="input-conjuge mb-0"
                color="primary">
                mdi-delete
                </v-icon>
                <v-icon
                @click="dialog_seleciona_empreitada = true"
                :disabled="store_Obra.acao == 'C'"
                class="ml-2"
                color="primary">
                mdi-magnify-plus
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- 4ª Linha - R.Inicio / R.Termino / Valor.P / Valor.R -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Data Realização Inicio -->
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_realizacao_inicio"
              readonly
              class="mb-0"
              loader-height="1"
              background-color="map-deep-merge grey lighten-1"
              label="Data Realização de Início"
              placeholder="Data Realização de Início"
              light
              maxlength="80"
              filled
              outlined
              dense
            ></DatePicker>
          </v-col>
          <!-- Data Realização Termino -->
          <v-col md="3">
            <DatePicker
              v-model="store_Obra.obra_edicao.dt_realizacao_termino"
              readonly
              class="mb-0"
              loader-height="1"
              background-color="map-deep-merge grey lighten-1"
              label="Data Realização Término"
              placeholder="Data Realização Término"
              light
              maxlength="80"
              filled
              outlined
              dense
            ></DatePicker>
          </v-col>
        <!-- Valor Previsto -->
          <v-col md="3">
            <v-text-field
              v-model="formattedValorPrevisto"
              label="Valor Previsto"
              placeholder="Valor Previsto"
              :readonly="true"
              class="mb-0"
              filled
              outlined
              dense
              background-color="map-deep-merge grey lighten-1"
              @input="updateValorPrevisto($event)"
            >
            </v-text-field>
          </v-col>
          <!-- Valor Realizado -->
          <v-col md="3">
            <v-text-field
              label="Valor Realizado"
              placeholder="Valor Realizado"
              :readonly="true"
              class="mb-0"
              :value="formattedValorRealizado"
              @input="updateValorRealizado($event)"
              filled
              outlined
              dense
              background-color="map-deep-merge grey lighten-1"
              item-text="name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- 5ª Linha - Situação -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Situação Consulta -->
          <v-col v-if="store_Obra.acao == 'C'" md="3">
            <v-text-field
              v-model="store_Obra.obra_edicao.situacao"
              class="mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Situação"
              placeholder="Situação"
              :rules="situacaoRules"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            >
            </v-text-field>
          </v-col>
          <!-- Situação Edição e Inclusão -->
          <v-col v-else md="3">
            <v-autocomplete
              :readonly="store_Obra.acao == 'C'"
              :items="situacao"
              v-model="store_Obra.obra_edicao.situacao"
              class="mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Situação"
              placeholder="Situação"
              :rules="situacaoRules"
              light
              maxlength="80"
              filled
              outlined
              required
              dense
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- 6ª Linha - Tabela Endereço da Obra -->
        <v-row v-if="store_Obra.acao == 'C'">
          <v-col cols="12">
            <v-data-table
              :headers="headers_enderecos"
              :items="store_Obra.obra_edicao.endereco_obra"
              :items-per-page="5"
              class="elevation-1"
              flat
              hide-default-footer
              no-data-text="Nenhum Endereço de Obra Cadastrado"
              loading-text="Carregando...  aguarde..."
              fixed-header
            >
              <!-- Titulo da tabela -->
              <template v-slot:top>
                <v-toolbar flat class="rounded-lg">
                  <p
                    class="font-primary font-weight-bold body-1 text-center mt-1"
                    :style="{ color: COR_SUBTITULO }"
                  >
                    Endereço da Obra
                  </p>
                  <v-spacer></v-spacer>
                  <!-- Botão Novo -->
                  <v-btn
                    v-if="!store_Obra.obra_edicao.endereco_obra.length"
                    @click="
                      (store_ModalObra.acao = 'I'),
                        (store_ModalObra.tipo = 'Endereco da Obra'),
                        ModalEndereco()
                    "
                    elevation="0"
                  >
                    <v-icon class="btn-icon-plus mr-1" color="green accent-4">
                      mdi-plus
                    </v-icon>
                    <span
                      class="font-weight-medium text-none"
                      :style="{ color: COR_SUBTITULO }"
                    >
                      Novo
                    </span>
                  </v-btn>
                  <div v-else class="d-flex">
                    <!-- Botão Editar -->
                    <div class="mr-3">
                      <v-btn @click="(store_ModalObra.acao = 'E'),(store_ModalObra.tipo = 'Endereco da Obra'),ModalEndereco()" elevation="0">
                        <v-icon class="btn-icon-plus" color="primary">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-btn @click="(store_ModalObra.tipo = 'Endereco da Obra'),ModalConfirmacao(item)" elevation="0">
                      <v-icon class="btn-icon-plus" color="red">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-toolbar>
              </template>

              <!-- Linha da Tabela -  -->
              <template #item="{ item }">
                <tr>
                  <!-- Dados Logradouro -->
                  <td class="py-3" style="width: 25%; font-size: 14px;">
                    {{ item.logradouro }}
                  </td>
                  <!-- Dados Numero -->
                  <td style="width: 5%; font-size: 14px;">
                    {{ item.numero }}
                  </td>
                  <!-- Dados Bairro -->
                  <td style="width: 25%; font-size: 14px;">
                    {{ item.bairro }}
                  </td>
                  <!-- Dados Cidade -->
                  <td style="width: 20%; font-size: 14px;">
                    {{ item.cidade }}
                  </td>
                  <!-- Dados Estado -->
                  <td style="width: 5%; font-size: 14px;">
                    {{ item.uf }}
                  </td>
                  <!-- Dados Cep -->
                  <td style="width: 10%; font-size: 14px;">
                    {{ item.cep }}
                  </td>
                  <!-- Dados Complemento -->
                  <td style="width: 10%; font-size: 14px;">
                    {{ item.complemento }}  
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- 7ª Linha - Tabela Endereço de Cobrança -->
        <v-row v-if="store_Obra.acao == 'C'">
          <v-col cols="12">
            <v-data-table
              :headers="headers_enderecos"
              :items="store_Obra.obra_edicao.endereco_cobranca"
              :items-per-page="5"
              class="elevation-1"
              flat
              hide-default-footer
              no-data-text="Nenhum Endereço de Cobrança Cadastrado"
              loading-text="Carregando...  aguarde..."
              fixed-header
            >
              <!-- Cabeçalho com Titulo da Tabela -->
              <template v-slot:top>
                <v-toolbar flat class="rounded-lg">
                  <p
                    class="font-primary font-weight-bold body-1 text-center mt-1"
                    :style="{ color: COR_SUBTITULO }"
                  >
                    Endereço da Cobrança
                  </p>

                  <v-spacer></v-spacer>
                  <!-- Botão Novo -->
                  <v-btn
                    v-if="!store_Obra.obra_edicao.endereco_cobranca.length"
                    @click="
                      (store_ModalObra.acao = 'I'),
                        (store_ModalObra.tipo = 'Endereco de Cobranca'),
                        ModalEndereco()
                    "
                    elevation="0"
                  >
                    <v-icon class="btn-icon-plus mr-1" color="green accent-4">
                      mdi-plus
                    </v-icon>
                    <span
                      class="font-weight-medium text-none"
                      :style="{ color: COR_SUBTITULO }"
                      >Novo</span
                    >
                  </v-btn>
                  <div v-else class="d-flex">
                    <!-- Botão Editar -->
                    <div class="mr-3">
                      <v-btn
                        @click="(store_ModalObra.acao = 'E'),
                            (store_ModalObra.tipo = 'Endereco de Cobranca'),
                            ModalEndereco()
                        "
                        elevation="0"
                      >
                        <v-icon class="btn-icon-plus" color="primary">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </div>
                    <!-- Botão Excluir -->
                    <v-btn
                    @click="(store_ModalObra.tipo = 'Endereco de Cobranca'),ModalConfirmacao(item)" elevation="0">
                      <v-icon class="btn-icon-plus" color="red">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-toolbar>
              </template>

              <!-- Linha da tabela - Endereços de Cobrança -->
              <template #item="{ item }">
                <tr>
                  <!-- Dados Logradouro -->
                  <td style="width: 25%; font-size: 14px">
                    {{ item.logradouro }}
                  </td>
                  <!-- Dados Numero -->
                  <td style="width: 5%; font-size: 14px">
                    {{ item.numero }}
                  </td>
                  <!-- Dados Bairro -->
                  <td style="width: 25%; font-size: 14px">
                    {{ item.bairro }}
                  </td>
                  <!-- Dados Cidade -->
                  <td style="width: 20%; font-size: 14px">
                    {{ item.cidade }}
                  </td>
                  <!-- Dados Estado -->
                  <td style="width: 5%; font-size: 14px">
                    {{ item.uf }}
                  </td>
                  <!-- Dados Cep -->
                  <td style="width: 10%; font-size: 14px">
                    {{ item.cep }}
                  </td>
                  <!-- Dados Complemento -->
                  <td style="width: 10%; font-size: 14px">
                    {{ item.complemento }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- Rodapé - Botões Salvar / Cancelar -->
      <v-footer
        v-if="store_Obra.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3 pb-n3"
        fixed
        style="position: static"
      >
        <v-btn
          v-if="store_Obra.acao == 'E'"
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <router-link
          v-else-if="store_Obra.acao == 'I'"
          :to="{ name: 'ObraConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn
            cer
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text
          >
            Cancelar
          </v-btn>
        </router-link>

        <v-btn
          :loading="loading"
          :disabled="!valid"
          @click="CadastraAtualizaObra()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-container v-if="dialog_crop">
      <v-dialog
        v-if="dialog_crop"
        :value="true"
        persistent
        style="width: 330"
        :max-width="330"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>Foto</v-card-title>
          <v-card-text class="pt-7">
            <VueCropImage
              :img="store_Obra.obra_edicao.foto_obra"
              :imgsync.sync="store_Obra.obra_edicao.foto_obra"
              :dialog_crop.sync="dialog_crop"
              ref="VueCropImage"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Modal de seleção de Empreitada -->
    <v-container v-if="dialog_seleciona_empreitada">
      <v-dialog
        v-model="dialog_seleciona_empreitada"
        transition="dialog-bottom-transition"
        max-width="440"
      >
        <v-card>
          <ModalSelecaoPessoa
            :ls_Selecionar="true"
            @selecionaPessoa="selecionaEmpreitada" />
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Modal de seleção de Cliente -->
    <v-container v-if="dialog_seleciona_cliente">
      <v-dialog
        v-model="dialog_seleciona_cliente"
        transition="dialog-bottom-transition"
        max-width="440"
        max-height="200"
      >
        <v-card>
          <ModalSelecaoPessoa
            :ls_Selecionar="true"
            @selecionaPessoa="selecionaCliente" />
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Modal de cadastro de enderecos -->
    <v-container v-if="store_ModalObra.dialog_cadastrar_endereco">
      <v-dialog
        v-model="store_ModalObra.dialog_cadastrar_endereco"
        transition="dialog-bottom-transition"
        max-width="440"
        persistent
      >
        <v-card>
          <ModalCadastrarEndereco />
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog v-model="dialogCancelar" transition="dialog-bottom-transition" max-width="410" class="pa-0">
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Deseja realmente Excluir este Registro?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogCancelar = false">
              Cancelar
            </v-btn>
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="ExcluiEndereco()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_Obra from "./store_Obra";
import store_site from "./../../../store/store_site";
import store_ModalObra from "./ModalObra/store_ModalObra";
import store_ModalNegociacao from '../../ModalNegociacao/store_ModalNegociacao'
import ModalCadastrarEndereco from "./ModalObra/ModalCadastrarEndereco.vue";
import ModalSelecaoPessoa from "../../ModalNegociacao/ModalSelecaoPessoa.vue";
import VueCropImage from "../../ComponentesGlobais/CropImage/VueCropImage.vue";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../services/API";

import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CNO,
} from "../../../services/constantes";
import { computed } from 'vue';

export default {
  name: "ObraCadastro",

  components: {
    DatePicker,
    VueCropImage,
    ModalCadastrarEndereco,
    ModalSelecaoPessoa
  },

  data() {
    return {
      store_site      : store_site,
      store_Obra      : store_Obra,
      store_ModalObra : store_ModalObra,
      store_ModalNegociacao : store_ModalNegociacao,

      MASK_CNO        : MASK_CNO,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SUBTITULO   : COR_SUBTITULO,
      COR_SECUNDARIA  : COR_SECUNDARIA,
      formatNumber    : formatNumber,
      formatDate      : formatDate,
      nomeIniciais    : nomeIniciais,

      valid           : true,
      alert           : false,
      alert_msg       : "",
      loading         : false,
      obra_edicaoAux  : null, // Dados a serem alterados da obra selecionada

      dialog_crop: false,
      dialogCancelar: false,
      dialog_seleciona_cliente: false,
      dialog_seleciona_empreitada: false,
      
      // Opcões de Selects estaticos
      opcoes: [{title: "Editar"},{title: "Excluir"}],
      situacao: ["Finalizada", "Em Andamento", "Parada"],

      nomeObraRules: [(value) => !!value || "O nome da obra é obrigatório"],
      cnoRules: [
        (value) => {
          if (value?.length > 0) {
            if (value?.length != 15) {
              return "O CNO não é válido";  
            }
          }
          return true;
        }
      ],
      situacaoRules: [(value) => !!value || "A situação é obrigatória"],

      headers_enderecos: [
        { text: "Logradouro", value: "logradouro", sortable: false },
        { text: "Número", value: "numero", sortable: false },
        { text: "Bairro", value: "bairro", sortable: false },
        { text: "Cidade", value: "cidade", sortable: false },
        { text: "UF", value: "uf", sortable: false },
        { text: "CEP", value: "cep", sortable: false },
        { text: "Compl", value: "complemento", sortable: false },
      ],
    };
  },

  watch: {
    "store_Obra.obra_edicao"(val) {
      this.atualizaAbas();
    },
  },

  async mounted() {
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Obra.obra_edicao?.cod_obra &&
        this.store_Obra.obra_edicao?.menu_abas
      ) {
        let abas = await this.store_Obra.menuAbas();
        this.store_Obra.abas = abas;
      }
    },

    async selecionaCliente(val) {
      this.store_Obra.obra_edicao.obra_cliente = {
        cod_pessoa: val.cod_pessoa,
        pessoa_nome: val.pessoa_nome,
      }
      this.store_Obra.obra_edicao.cod_cliente = val.cod_pessoa;
      this.dialog_seleciona_cliente = false;
    },

    async selecionaEmpreitada(val) {
      this.store_Obra.obra_edicao.obra_empreitada = {
        cod_pessoa: val.cod_pessoa,
        pessoa_nome: val.pessoa_nome,
      }
      this.store_Obra.obra_edicao.cod_empreitada = val.cod_pessoa;
      this.dialog_seleciona_empreitada = false;
    },

    // Métodos da Obra
    async CadastraAtualizaObra() {
      const lb_valido = this.$refs.form.validate();
      if(lb_valido) {
        let ls_Rel = null;
        var lo_params = {...JSON.parse(JSON.stringify(this.store_Obra.obra_edicao))};
        if (this.store_Obra.acao == "I") {
          ls_Rel = await this.store_Obra.ObraPost(lo_params);
          this.store_Obra.obra_edicao.cod_obra = ls_Rel.data.result.data.cod_obra;
        }
        else if (this.store_Obra.acao == "E") {
          ls_Rel = await this.store_Obra.ObraPut(lo_params);
        }
        if (ls_Rel.status == 200 ) {
          this.store_Obra.acao = "C";
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
        }
        else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result;
          this.store_site.alert = true;
        }
      }
    },

    async ExcluiEndereco() {
      let ls_Rel = null;
      this.dialogCancelar = false;
      if (this.store_ModalObra.tipo == "Endereco da Obra") {
        this.store_Obra.obra_edicao.endereco_obra[0] ={
          ...this.store_Obra.obra_edicao.endereco_obra[0],
          cod_endereco: Number(this.store_Obra.obra_edicao.endereco_obra[0].cod_endereco),
        };
        ls_Rel = await this.store_Obra.EnderecoDelete(this.store_Obra.obra_edicao.endereco_obra[0]);
      } else {
        ls_Rel = await this.store_Obra.EnderecoDelete(this.store_Obra.obra_edicao.endereco_cobranca[0]);
      }
      if (ls_Rel.status == 200) {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_msg = ls_Rel.data.result.result.trim();
        this.store_site.alert = true;
      } else {
        this.store_site.alert_cor = "#FF0000";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg = ls_Rel.data.result.trim();
        this.store_site.alert = true;
      }
      var lo_params = { cod_obra: this.store_Obra.obra_edicao.cod_obra };
      if (lo_params.cod_obra) {
        const lo_Res = await this.store_Obra.ObraGet(lo_params);
        if (lo_Res) {
          this.store_Obra.obra_edicao = { ...lo_Res };
        }
      }
    },

    cancelar() {
      if (this.store_Obra.acao == "I") {
        this.obra_edicaoAux = null;
      } else if (this.store_Obra.acao == "E") {
        this.store_Obra.obra_edicao = {
          ...this.obra_edicaoAux,
        };
      }
      this.store_Obra.acao = "C";
    },

    // Abre Modal de Endereço
    ModalEndereco() {
      if (this.store_ModalObra.tipo == "Endereco da Obra")
        this.store_ModalObra.cadastro_obraAux = {
          ...this.store_Obra.obra_edicao.endereco_obra[0],
        };
      else
        this.store_ModalObra.cadastro_cobrancaAux = {
          ...this.store_Obra.obra_edicao.endereco_cobranca[0],
        };
      this.store_ModalObra.step = "ModalCadastrarEndereco";
      this.store_ModalObra.dialog_cadastrar_endereco = true;
    },

    ModalConfirmacao(item){
      this.dialogCancelar = true;
    },

    fecha_alert() {
      this.alert = false;
    },
    updateValorPrevisto(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Obra.obra_edicao.valor_previsto = numericValue;
    },
    updateValorRealizado(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Obra.obra_edicao.valor_realizado = numericValue;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    formattedValorPrevisto() {
      // Formata o valor para exibição
      let valor = this.store_Obra.obra_edicao.valor_previsto;
      return formatNumber(valor);
    },
    formattedValorRealizado() {
      // Formata o valor para exibição
      let valor = this.store_Obra.obra_edicao.valor_realizado;
      return formatNumber(valor);
    },
  },
};
</script>

<style scoped>
#ObraCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media (max-width: 599px) {
  #ObraCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#ObraCadastro::-webkit-scrollbar {
  width: 5px;
}

#ObraCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#ObraCadastro::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#ObraCadastro::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #ObraCadastro::-webkit-scrollbar {
    width: initial;
  }

  #ObraCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #ObraCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #ObraCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  color: #ff0000;
}
</style>
