<template>
  <div id="ObraOrcamento" class="pa-3">
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Orçamento
      </p>
    </v-toolbar-title>


    <v-card width="100%" outlined>
      <v-card-title>
        <v-btn
          class="text-white text-capitalize mr-2 ma-5"
          :color="COR_PRINCIPAL"
          elevation="0"
          @click="store_ModalObra.dialog_cadastrar_insumo = true"
        >
          <v-icon class="mr-1" color="light-blue accent-2">mdi-invoice-text-plus-outline</v-icon
          >
          Novo Insumo
        </v-btn>
      </v-card-title>
      <v-card-text class="m-5" >

    <v-form ref="form">

    <v-data-table
      :items="dados"
      item-key="cod_insumo"
      hide-default-footer
    >
      <template v-slot:header>
        <thead
          @mouseover="hover = -1"
          @mouseleave="hover = null">
          <tr>
            <th width="60px!important" class="td-borda text-title">Ações</th>
            <th  class="td-borda text-title">Descrição</th>
            <th width="100px!important" class="td-borda text-title">Categoria</th>
            <th width="70px!important" class="td-borda text-title">Qtde</th>
            <th width="25px!important" class="td-borda text-title">Unid</th>
            <th width="100px!important" class="td-borda text-title">Valor Unit.</th>
            <th width="130px!important" class="td-borda text-title">Total</th>
            <th width="36px!important" class="td-borda text-title"></th>
          </tr>
        </thead>
      </template>

      <template v-slot:body="props">
        <draggable
              :list="props.items"
              @end="onReorder"
              tag="tbody"
              handle=".page__grab-icon"
            >
        <tr
          v-for="(row, index) in dados"
          :key="index"
          :style="(row.tipo || editedItem.tipo) == 'Etapa' ? {'background-color': '#E9E9E9', 'font-weight': '600'} : null"
          @mouseover="hover = index"
          @mouseleave="hover = null"
        >
          <!-- Botoes -->
          <td v-if="editedIndex == -1 && hover == index" width="40px!important" class="align-center">
                <v-btn
                  class="ml-n4 mt-n7 custom-button-top"
                  rounded
                  fab
                  dense
                  color="#C0FFD0"
                  @click="index == 0 ? addNew(0) : addNew(index); this.foco('#tipo');"
                >
                  <v-icon dark small class="mt-0">mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  class="ml-n5 mb-n7 custom-button-bottom"
                  rounded
                  fab
                  dense
                  color="#C0FFD0"
                  @click="addNew(index + 1)"
                >
                  <v-icon dark small class="mt-0">mdi-plus</v-icon>
                </v-btn>

                <v-icon
                    @mouseover="isHovering = index"
                    @mouseleave="isHovering = null"
                    color="#00D000"
                    class="ml-2 custom-button-move z-index-max"
                    :class="{'page__grab-icon': true, 'mdi-arrow-all': isHovering === index}"
                    style="cursor: grab;"
                  >
                    mdi-arrow-all
                  </v-icon>
          </td>

          <!-- Codigo -->
          <td v-else width="40px!important" class="align-right">
            {{ row.codigo }}
          </td>

          <!-- Tipo -->
          <!-- <td width="60px!important" class="td-borda align-right">
            <v-autocomplete
              v-if="editedIndex == index"
              class="custom-autocomplete pb-n3"
              dense
              :hide-details="true"
              v-model="editedItemTipo"
              :items="arrayTipos"
              @change="changeTipo(editedItemTipo)"
            ></v-autocomplete>
          </td> -->

          <!-- Descrição -->
          <td class="td-borda">
            <v-autocomplete
              v-if="editedIndex == index"
              class="custom-autocomplete pb-n3"
              dense
              :hide-details="true"
              v-model="editedItem"
              :items="arrayItensFiltro"
              item-text="nome_insumo"
              item-value="cod_insumo"
              return-object
            ></v-autocomplete>
            <span v-else @click="editItem(row)" class="text-nome">{{ row.nome_insumo }}</span>
          </td>

          <!-- Categoria -->
          <td width="100px!important" class="td-borda">
            <span v-if="editedIndex == index">{{ editedItem.categoria }}</span>
            <v-date-picker
              v-if="editedItemTipo == 'Etapa'"
              range
              v-model="data"
            ></v-date-picker>
          </td>

          <!-- Quantidade -->
          <td width="70px!important" class="td-borda align-right">
            <v-text-field
              v-if="editedIndex == index && editedItem.tipo == 'Item'"
              v-model="editedItem.qtde_unit"
              dense
              :hide-details="true"
            ></v-text-field>
            <span v-else @click="editItem(row)" class="text-nome">{{ row.qtde_unit }}</span>
          </td>

          <!-- Unidade -->
          <td width="25px!important" class="td-borda align-center">
            <span>{{ row.unidade }}</span>
          </td>

          <!-- Valor Unitário -->
          <td width="100px!important" class="td-borda align-right">
            <VuetifyMoney
              v-if="editedIndex == index && editedItem.tipo == 'Item'"
              :value="editedItem.valor_unitario"
              v-model="editedItem.valor_unitario"
              dense
              reverse
              :options="{ locale: 'pt-BR', prefix: '', suffix: '', length: 11, precision: 2 }"
            ></VuetifyMoney>
            <span v-else @click="editItem(row)" class="text-nome">{{ formatNumber(row.valor_unitario) }}</span>
          </td>

          <!-- Total -->
          <td width="130px!important" class="td-borda align-right">
            <span>{{ formatNumber(row.total) }}</span>
          </td>

          <!-- Botoes de Excluir ou Salvar -->
          <td :style="{'max-width':'35px!important'}">
            <v-btn
              v-if="editedIndex != -1 && editedIndex == index"
              rounded
              fab
              dense
              color="green"
              class="custom-button-right"
              @click="SaveItem(editedIndex)"
            >
              <v-icon dark small color="white accent-1">mdi-check</v-icon>
            </v-btn>
            <v-btn
              v-else-if="editedIndex == -1 && hover == index"
              rounded
              fab
              dense
              color="red"
              class="custom-button-right"
              @click="deleteItem(index)"
            >
              <v-icon dark small color="white accent-1">mdi-minus</v-icon>
            </v-btn>
          </td>
        </tr>
        </draggable>

        <tr v-if="dados.length <= 0" class="align-center">
          <td colspan="8">
            <v-btn
              small
              rounded
              fab
              dense
              class="custom-button-start"
              color="#C0FFD0"
              @click="addNew(0)"
            >
              <v-icon dark small>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>

      </template>

    </v-data-table>
  </v-form>

    <!-- ================================================================================================================ -->
    <!-- ================================================================================================================ -->

      <!-- <v-data-table
        :headers="headers"
        :items="dados"
        :search="search"
        class="elevation-1 mt-10"
        fixed-header
        height="350px">
        <v-divider inset></v-divider>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <div class="d-flex w-100">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                dense
                outlined
                single-line
                hide-details></v-text-field>
              <v-btn
                color="primary"
                class="ml-2 white--text"
                @click="addNew">
                <v-icon dark>mdi-plus</v-icon>Add
              </v-btn>
            </div>
          </v-toolbar>
        </template>
        <template #item="{ item, index }">
          <tr>
            <td>
{{ index }}
                <v-text-field v-model="editedItem.cod_insumo" :hide-details="true" dense single-line :autofocus="true" v-if="item.cod_insumo === editedItem.cod_insumo"></v-text-field>
                <span v-else>{{item.cod_insumo}}</span>
            </td>
            <td>
                <v-text-field v-model="editedItem.nome" :hide-details="true" dense single-line v-if="item.cod_insumo === editedItem.cod_insumo" ></v-text-field>
                <span v-else>{{item.nome}}</span>
            </td>
            <td>
                <div v-if="item.id === editedItem.id">
                  <v-icon color="red" class="mr-3" @click="close">
                    mdi-window-close
                  </v-icon>
                  <v-icon color="green"  @click="save">
                    mdi-content-save
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon color="green" class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="red" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </div>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table> -->

    </v-card-text>
    </v-card>

    <!-- modal cadastrar insumo -->
    <v-container v-if="store_ModalObra.dialog_cadastrar_insumo">
      <v-dialog
        v-model="store_ModalObra.dialog_cadastrar_insumo"
        transition="dialog-bottom-transition"
        max-width="440px"     
        persistent          
        >
        <v-card style="height: 500px">
          <ModalCadastrarInsumo
            @close="buscaInsumos"/>
        </v-card>
      </v-dialog>
    </v-container>

  </div>
</template>

<script>
import Vue from "vue";
import store_ModalObra from '../ModalObra/store_ModalObra'

import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
  COR_BACKGROUND
} from "../../../../services/constantes";
import { formatDate, formatNumber } from "../../../../services/funcoes";
import DatePicker from "../../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../../services/API";
import VuetifyMoney from '../../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
import ModalCadastrarInsumo from "../../Insumo/ModalInsumo/ModalCadastrarInsumo.vue";
import Draggable from 'vuedraggable';

export default {
  name: "ObraOrcamento",
  components: {
    VuetifyMoney,
    ModalCadastrarInsumo,
    //DatePicker,    
    Draggable
  },

  data: () => ({

    store_ModalObra   : store_ModalObra,

    COR_PRINCIPAL     : COR_PRINCIPAL,
    COR_SECUNDARIA    : COR_SECUNDARIA,
    COR_SUBTITULO     : COR_SUBTITULO,
    COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
    COR_BACKGROUND    : COR_BACKGROUND,

    formatNumber    : formatNumber,

    search: '',

    dados             : [],
    arrayItens        : [],
    arrayItensFiltro  : [],
    arrayTipos        : [],
    editedIndex       : -1,
    editedItemTipo    : 'Item',
    editedItem : {
      cod_insumo      : null,
      tipo            : null,
      codigo          : null,
      nome_insumo     : null,
      categoria       : null,
      periodo         : null,
      qtde_unit       : null,
      unidade         : null,
      valor_unitario  : null,
      total           : null,
    },
    data              : [],
    hover             : null,
    isHovering        : null,

     headers : [
      { text: "Test Value", value: "testValue" },
      { text: "", value: "actionUp" },
      { text: "", value: "actionDown" }
    ],
    item_dados : [],
    items: [
        {
          action: 'local_activity',
          title: 'Parent: Attractions',
          items: [
            { title: 'Child: Sailing',
              items: [
                  { title: 'GrandChild A - How to put this on its own row?' },
                  { title: 'GrandChild B - How to put this on its own row?'  }]
            }
          ]
        },
        {
          action: 'restaurant',
          title: 'Parent: Dining',
          active: true,
          items: [
            { title: 'Child: Breakfast & brunch', 
                items: [
                  { title: 'GrandChild 0  - How to put this on its own row?'  },
                  { title: 'GrandChild 1 - How to put this on its own row?'  }
          ]},
            { title: 'Child: New American' },
            { title: 'Child: Sushi' }
          ]
        },
        
      ]    
  }),
  created () {
    this.initialize();
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
    for (let i = 0; i < 20; i++) {
        this.item_dados.push({ id: i, testValue: "testValue " + i });
      }
  },

  // Create data example
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  watch: {
    //"editedItemTipo"(val) {
      //console.log('editedItemTipo', val)
      //this.editedItem.tipo == this.editedItemTipo
    //},

    "editedItem.qtde_unit"(val) {
      this.editedItem.total = null;
      if (this.editedItem.qtde_unit && this.editedItem.valor_unitario) {
        this.editedItem.total = this.editedItem.qtde_unit * this.editedItem.valor_unitario;
      }
    },
    "editedItem.valor_unitario"(val) {
      this.editedItem.total = null;
      if (this.editedItem.qtde_unit && this.editedItem.valor_unitario) {
        this.editedItem.total = this.editedItem.qtde_unit * this.editedItem.valor_unitario;
      }
    },
  },
  methods: {

    initialize () {

      this.buscaInsumos('Item');     
      this.dados = [
      {
          cod_insumo        : 1,
          tipo            : 'Etapa',
          codigo          : '1',
          nome_insumo     : 'Serviços Preliminares',
          categoria       : null,
          periodo         : null,
          qtde_unit       : null,
          unidade         : null,
          valor_unitario  : null,
          total           : 917.50,
        },
        {
          cod_insumo        : 2,
          tipo            : 'Etapa',
          codigo          : '1.1',
          nome_insumo     : 'Limpeza de Terreno',
          categoria       : null,
          periodo         : '01/07/2024 - 20/07/2024',
          qtde_unit       : null,
          unidade         : null,
          valor_unitario  : null,
          total           : 917.50,
        },
        {
          cod_insumo        : 3,
          tipo            : 'Item',
          codigo          : '1.1.1',
          nome_insumo     : 'Servente de Pedreiro',
          categoria       : 'Mão Obra',
          periodo         : null,
          qtde_unit       : 4,
          unidade         : 'hr',
          valor_unitario  : 150,
          total           : 600,
        },
        {
          cod_insumo        : 4,
          tipo            : 'Item',
          codigo          : '1.1.2',
          nome_insumo     : 'Aluguel Caçambra',
          categoria       : 'Equipamento',
          periodo         : null,
          qtde_unit       : 2,
          unidade         : 'dia',
          valor_unitario  : 150,
          total           : 300,
        },
        {
          cod_insumo        : 5,
          tipo            : 'Item',
          codigo          : '1.1.3',
          nome_insumo     : 'Fio de Nylon',
          categoria       : 'Material',
          periodo         : null,
          qtde_unit       : 7,
          unidade         : 'un',
          valor_unitario  : 2.50,
          total           : 17.50,
        },
      ]
      this.dados = [];

      this.arrayTipos = [ 'Etapa',
                          'Item'];

    },
    async buscaInsumos() {
      this.store_ModalObra.dialog_cadastrar_insumo = false;
      const lo_Insumos = await API.get('/insumos', {});
      this.arrayItens = [];
      if (lo_Insumos.status == 200) {
        this.arrayItens   = lo_Insumos.data.result;
        this.changeTipo('Item');
        console.log('lo_Insumos', JSON.parse(JSON.stringify(this.arrayItens)));
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        console.log('A tecla ESC foi pressionada!');
        this.cancelIndex(this.editedIndex);
      }
      if (event.key === 'Enter') {
        console.log('A tecla ENTER foi pressionada!');
        this.focusNextField();
      }

    },

    onReorder(e) {
      let lo_Old = this.dados[e.oldIndex];
      let lo_New = this.dados[e.newIndex];
      console.log('--------------', e.oldIndex, lo_Old, ' / ', e.newIndex, lo_New)
      Vue.set(this.dados, e.newIndex, lo_Old);
      Vue.set(this.dados, e.oldIndex, lo_New);
    },

    // Move items up in the array
    moveUp(id) {
      const index = this.items.findIndex(e => e.id == id);
      if (index > 0) {
        const el = this.items[index];
        $root.set(this.items, index, this.items[index - 1]);
        $root.set(this.items, index - 1, el);
      }
    },
    // Move items down in the array
    moveDown(id) {
      const index = this.items.findIndex(e => e.id == id);
      debugger;
      if (index !== -1 && index < this.items.length - 1) {
        const el = this.items[index];
        $root.set(this.items, index, this.items[index + 1]);
        $root.set(this.items, index + 1, el);
      }
    },  

    editItem(item) {
      console.log('editItem', item);
      this.editedItemTipo = item.tipo
      this.editedIndex = this.dados.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.acao = 'E';
    },

    deleteItem (index) {
      confirm('Tem certeza que deseja retirar este item?') && this.dados.splice(index, 1);
    },

    // close () {
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   }, 300)
    // },
    cancelIndex(idx) {
      if (this.editedIndex == -1)
        return
      if (this.editedItem.acao == 'I')
        this.dados.splice(idx, 1);
      this.editedIndex = -1;
      this.editedItem = {
        acao            : null,
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
    },
    addNew(idx) {
      // O objeto que você deseja adicionar
      let newObject = {
        acao            : 'I',
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
      // Adicionando o novo objeto na posição 6 (índice 5)
      this.dados.splice(idx, 0, newObject);
      this.editedIndex  = idx;
      this.editedItem   = {...newObject};      
    },
    SaveItem(index) {
      let newObject = {
        cod_insumo      : this.dados.length+1,
        tipo            : this.editedItem.tipo,
        codigo          : '1.5',
        nome_insumo     : this.editedItem.nome_insumo,
        categoria       : this.editedItem.categoria,
        periodo         : null,
        qtde_unit       : this.editedItem.qtde_unit,
        unidade         : this.editedItem.unidade,
        valor_unitario  : this.editedItem.valor_unitario,
        total           : this.editedItem.total,
      };
      Object.assign(this.dados[index], newObject)
      this.editedIndex = -1;
      this.editedItem = {
        acao            : null,
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
      this.addNew(index+1);
      this.foco('#nome_insumo');
    },
    prepararCadastroInsumo(){
      this.store_ModalObra.acao = "I";
      this.store_ModalObra.cadastro_insumo = {}
      this.cadastrarInsumo();
    },

    cadastrarInsumo() {
      this.store_ModalObra.step = "ModalCadastrarInsumo";
      this.store_ModalObra.dialog_cadastrar_insumo = true;
    },
    foco(elem_id) {
      this.$nextTick(() => {
        let elem_focus = document.querySelector(elem_id)
        elem_focus?.focus();
      });      
    },
    async changeTipo(tipo) {
      console.log('Tipo', tipo)
      const lo_Res = this.arrayItens.filter((o) => {return o.tipo == tipo});
      this.arrayItensFiltro = [];
      this.arrayItensFiltro.push(...lo_Res);
    },
    focusNextField() {
      const form = this.$refs.form.$el;
      const focusableElements = form.querySelectorAll(
        'input, select, textarea, button, [tabindex]:not([tabindex="-1"])'
      );

      // Find the currently focused element
      const currentIndex = Array.prototype.indexOf.call(
        focusableElements,
        document.activeElement
      );

      // Focus the next element, if it exists
      if (currentIndex !== -1 && currentIndex + 1 < focusableElements.length) {
        focusableElements[currentIndex + 1].focus();
      }
    }
  },
  
};
</script>

<style scoped>

.custom-text-field .v-input__control {
  padding: 0 !important; /* Remove padding do controle de entrada */
}

.custom-text-field .v-input__slot {
  padding: 0 !important; /* Remove padding do slot de entrada */
}

.custom-text-field .v-text-field__slot {
  padding: 0 !important; /* Remove padding do slot específico do v-text-field */
}

.custom-text-field input {
  padding: 0 !important; /* Remove padding do campo de entrada */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-text-field .v-label {
  padding: 0 !important; /* Remove padding do label */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-text-field .v-messages__wrapper {
  padding: 0 !important; /* Remove padding das mensagens */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

/* ------------------------------------------------------------------------------- */

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #555;
  color: #fff;
}
.w-100 {
  width: 100%
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.v-btn {
  display: inline-block;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.td-borda {
  border-right: 1px solid #EEEEEE;
  padding: 3px;
}

thead th:hover {
  background-color: #F3F3F3;
}

thead th {
  padding: 3px;
  font-weight: 600;
}

.z-index-max {
  z-index: 20 !important;
}

.custom-button-start {
  width: 30px !important;
  height: 30px !important;
  margin: 10px !important;;
  padding: 0 !important;
  object-position: absolute;
  top: 0px !important;
  left: 0px !important;
  z-index: 20 !important;
}

.custom-button-top {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  object-position: absolute;
  left: 0px !important;
  top: -5px !important;
  z-index: 20 !important;
}

.custom-button-bottom {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  object-position: absolute;
  left: 0px !important;
  bottom: -5px !important;
  z-index: 20 !important;
}

.custom-button-move {
  width: 25px !important;
  height: 25px !important;
  object-position: absolute;
  left: 10px !important;
  top: 0px !important;
  z-index: 20 !important;
}

.custom-button-right {
  width: 20px !important;
  height: 20px !important;
  object-position: absolute;
  right: 10px !important;
  top: 0px !important;
  z-index: 20 !important;
}

tr {
  height: 35px !important;
}

/* tr:hover {
  background-color: #F3F3F3;
} */

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.text-title {
  color: #FFFFFF!important;  
  text-align: center!important;
}

.custom-autocomplete .v-input__control {
  min-height: 30px; /* Ajuste a altura mínima conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-input__slot {
  height: 30px; /* Ajuste a altura do slot conforme necessário */
  padding: 0px; /* Ajuste o padding conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-select__selections {
  line-height: 30px; /* Ajuste a altura da linha conforme necessário */
}

.custom-autocomplete input {
  height: 30px; /* Ajuste a altura do input conforme necessário */
  line-height: 30px; /* Ajuste a altura da linha do input conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-label {
  top: -20px; /* Ajuste a posição do label conforme necessário */
  font-size: 12px; /* Ajuste o tamanho da fonte do label conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-select__selections > div {
  margin-top: -5px; /* Ajuste a margem superior conforme necessário */
  padding: 0px;
}

.text-nome:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--COR_SUBTITULO);
}

/* ::v-deep .theme--light.v-input input, .theme--light.v-input textarea .v-input .v-input__slot div.v-text-field__slot fieldset .input input  {          */
  /* border: 1px solid rgba(255, 0, 0, 0.2) !important; */
  /* background-color: rgba(255, 0, 0, 0.2) !important; */
  /* margin-left: -9px !important; */
  /* height: 30px !important;; */
  /* width: 100% !important;; */
/* } */

::v-deep .theme--light.v-input input {
  border: 0px solid rgba(255, 0, 0, 0.2) !important;
  background-color: rgba(0, 255, 0, 0.1) !important;
}

/*
::v-deep .v-application--is-ltr .v-text-field--reverse input {
  padding: 0px !important;
} */

</style>
