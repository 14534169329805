<template>
  <div id="ObraOrcamento" class="pa-3">
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Etapas
      </p>
    </v-toolbar-title>


    <v-card width="100%" outlined>
      <!-- <v-card-title>
        <v-btn
          class="text-white text-capitalize mr-2 ma-5"
          :color="COR_PRINCIPAL"
          elevation="0"
          @click="store_ModalObra.dialog_cadastrar_insumo = true"
        >
          <v-icon class="mr-1" color="light-blue accent-2">mdi-invoice-text-plus-outline</v-icon
          >
          Novo Insumo
        </v-btn>
      </v-card-title> -->
      <v-card-text class="m-5" >

        <v-form ref="form">

          <v-data-table
            :items="dados"
            item-key="cod_insumo"
            hide-default-footer
          >
            <template v-slot:header>
              <thead
                @mouseover="hover = -1"
                @mouseleave="hover = null">
                <tr>
                  <!-- <th width="15px!important" class="td-borda-right text-title"></th>
                  <th width="15px!important" class="td-borda-right text-title"></th>
                  <th width="15px!important" class="td-borda-right text-title"></th> -->
                  <th width="30px!important"></th>
                  <th class="td-borda-right text-title">Descrição</th>
                  <th width="60px!important" class="td-borda-right text-title">Prev Inicial</th>
                  <th width="80px!important" class="td-borda-right text-title">Prev Final</th>
                  <th width="60px!important" class="td-borda-right text-title">Real Inicial</th>
                  <th width="60px!important" class="td-borda-right text-title">Real Final</th>
                  <th width="60px!important" class="td-borda-right text-title">Custo Prev</th>
                  <th width="60px!important" class="td-borda-right text-title">Custo Real</th>
                  <th width="30px!important" class="td-borda-right text-title"></th>
                </tr>
              </thead>
            </template>

            <template v-slot:body="props">
              <draggable
                :list="props.items"
                @end="onReorder"
                tag="tbody"
                handle=".page__grab-icon"
              >
                <tr
                  v-for="(row, index) in dados"
                  :key="index"
                  @mouseover="hover = index"
                  @mouseleave="hover = null"
                  :class="(row.nivel) == 3 ? 'nivel-3-background'
                                            : (row.nivel) == 2 ? 'nivel-2-background'
                                                              : 'nivel-1-background'"
                >
                
                <!-- :style="(row.nivel) == 3 ? {'background-color': 'rgba(0, 0, 0, 0.05)', 'transform': 'translateX(60px)', 'width':'200px!important'}
                                          : (row.nivel) == 2 ? {'background-color': 'rgba(0, 0, 0, 0.15)', 'transform': 'translateX(30px)'}
                                                              : {'background-color': 'rgba(0, 0, 0, 0.25)'}" -->

                <!-- :style="(row.nivel) == 1 ? {'background-color': '#DEEEDE'} : {'background-color': '#EEFFEE'}" -->

                  <!-- Botoes -->
                  <!-- <td v-if="editedIndex == -1 && hover == index" width="40px!important" class="align-center">
                        <v-btn
                          class="ml-n4 mt-n7 custom-button-top"
                          rounded
                          fab
                          dense
                          color="#C0FFD0"
                          @click="index == 0 ? addNew(0) : addNew(index); this.foco('#tipo');"
                        >
                          <v-icon dark small class="mt-0">mdi-plus</v-icon>
                        </v-btn> -->
                        <!-- <v-btn
                          class="ml-n5 mb-n7 custom-button-bottom"
                          rounded
                          fab
                          dense
                          color="#C0FFD0"
                          @click="addNew(index + 1)"
                        >
                          <v-icon dark small class="mt-0">mdi-plus</v-icon>
                        </v-btn> -->

                        <!-- <v-icon
                            @mouseover="isHovering = index"
                            @mouseleave="isHovering = null"
                            color="#00D000"
                            class="ml-2 custom-button-move z-index-max"
                            :class="{'page__grab-icon': true, 'mdi-arrow-all': isHovering === index}"
                            style="cursor: grab;"
                          >
                            mdi-arrow-all
                          </v-icon>
                  </td> -->

                  <!-- Codigo -->
                  <!-- <td v-if="row.nivel == 1"
                    width="45px!important"
                    class="align-right">
                    <tr>
                      <div v-if="row.nivel == 1"
                        width="15px!important"
                        class="align-right">
                        {{ row.codigo }}
                      </div>
                      <td v-else
                          width="15px!important"
                          class="align-right">
                        -
                      </td>
                    </tr>
                  </td> -->

                  <!-- Tipo -->
                  <!-- <td width="60px!important" class="td-borda-right align-right">
                    <v-autocomplete
                      v-if="editedIndex == index"
                      class="custom-autocomplete pb-n3"
                      dense
                      :hide-details="true"
                      v-model="editedItemTipo"
                      :items="arrayTipos"
                      @change="changeTipo(editedItemTipo)"
                    ></v-autocomplete>
                  </td> -->

                <!-- -->

                <!-- :style="(row.nivel) == 3 ? {'background-color': 'rgba(0, 0, 0, 0.05)', 'transform': 'translateX(60px)', 'width':'200px!important'}
                                          : (row.nivel) == 2 ? {'background-color': 'rgba(0, 0, 0, 0.15)', 'transform': 'translateX(30px)'}
                                                              : {'background-color': 'rgba(0, 0, 0, 0.25)'}" -->


                <!-- Menu de Botoes de Ações -->
                <td
                  style="width: 30px!important; background-color: #FFFFFF;"
                  class="px-0 py-0">
                  <v-menu
                    content-class="my-menu" 
                    transition="slide-y-transition"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ObraOrcamentoEtapa_alterar')"
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"

                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <!-- Adicionar de Todas as Aplciações -->
                    <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
                      <v-list-item
                        @click="adicionarTodos()">
                        <v-icon color="green" class="mr-2 icon-menu">
                          mdi-pencil
                        </v-icon>
                        Adicionar eventos de todas as aplicações
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        @click="removerTodos()">
                        <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                        Remover eventos de todas as aplicações
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        @click="dialogoUsuarios = true">
                        <v-icon color="blue" class="mr-2 icon-menu">mdi-account-switch</v-icon>
                        Copiar os privilégios de outro usuário
                      </v-list-item>
                    </v-card>
                    <!-- FIM Adicionar de Todas as Aplciações -->
                  </v-menu>
                </td>

                <!-- Codigo e Descrição -->
                <td
                  style="width: 100%!important;"
                  class="px-0 py-0 d-flex">
                  <div
                    v-if="row.nivel === 2"
                    style="width: 15px!important; height: 100%!important; background-color: rgba(255, 255, 255, 1)!important">
                  </div>
                  <div
                    v-if="row.nivel === 3"
                    style="width: 30px!important; height: 100%!important; background-color: rgba(255, 255, 255, 1)!important">
                  </div>
                  <div
                    class="alinhamento-vertical-centro ml-2">
                    {{ row.codigo }} - {{ row.nome_etapa }}
                  </div>
                </td>

                <!-- Previsao Inicial -->
                <td
                  class="px-0"
                  width="60px!important"
                >
                  <div
                    class="alinhamento-vertical-centro">
                    {{ formatDate(row.dt_previsao_inicio) }}
                  </div>
                </td>

                <!-- Previsao Final -->
                <td width="60px!important">
                  <div
                    class="alinhamento-vertical-centro">
                    {{ formatDate(row.dt_previsao_termino) }}
                  </div>
                </td>

                <!-- Realizado Inicial -->
                <td width="60px!important">
                  <div
                    class="alinhamento-vertical-centro">
                    {{ formatDate(row.dt_realizacao_inicio) }}
                  </div>
                </td>

                <!-- Realizado Termino -->
                <td width="60px!important">
                  <div
                    class="alinhamento-vertical-centro">
                    {{ formatDate(row.dt_realizacao_termino) }}
                  </div>
                </td>

                <!-- Custo Previsto -->
                <td width="60px!important" class="align-right">
                  <div
                    class="alinhamento-vertical-centro align-right">
                    {{ formatNumber(row.custo_total_previsto) }}
                  </div>
                </td>

                <!-- Custo Realizado -->
                <td width="60px!important">
                  <div
                    class="alinhamento-vertical-centro align-right">
                    {{ formatNumber(row.custo_total_realizado) }}
                  </div>
                </td>

                  <!-- Botoes de Excluir ou Salvar -->
                  <td :style="{'max-width':'30px!important'}">
                    <v-btn
                      v-if="editedIndex != -1 && editedIndex == index"
                      rounded
                      fab
                      dense
                      color="green"
                      class="custom-button-right"
                      @click="SaveItem(editedIndex)"
                    >
                      <v-icon dark small color="white accent-1">mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      v-else-if="editedIndex == -1 && hover == index"
                      rounded
                      fab
                      dense
                      color="red"
                      class="custom-button-right"
                      @click="deleteItem(index)"
                    >
                      <v-icon dark small color="white accent-1">mdi-minus</v-icon>
                    </v-btn>
                  </td>

                </tr>
              </draggable>
            </template>

              <template v-slot:footer>
                <tr class="align-center">
                <td colspan="9">
                    <v-btn
                      small
                      rounded
                      fab
                      dense
                      class="custom-button-start mt-2"
                      color="#209050"
                      @click="addNew(dados.length)"
                    >
                      <v-icon dark small>mdi-plus</v-icon>
                    </v-btn>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-form>

    <!-- ================================================================================================================ -->
    <!-- ================================================================================================================ -->

      <!-- <v-data-table
        :headers="headers"
        :items="dados"
        :search="search"
        class="elevation-1 mt-10"
        fixed-header
        height="350px">
        <v-divider inset></v-divider>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <div class="d-flex w-100">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                dense
                outlined
                single-line
                hide-details></v-text-field>
              <v-btn
                color="primary"
                class="ml-2 white--text"
                @click="addNew">
                <v-icon dark>mdi-plus</v-icon>Add
              </v-btn>
            </div>
          </v-toolbar>
        </template>
        <template #item="{ item, index }">
          <tr>
            <td>
{{ index }}
                <v-text-field v-model="editedItem.cod_insumo" :hide-details="true" dense single-line :autofocus="true" v-if="item.cod_insumo === editedItem.cod_insumo"></v-text-field>
                <span v-else>{{item.cod_insumo}}</span>
            </td>
            <td>
                <v-text-field v-model="editedItem.nome" :hide-details="true" dense single-line v-if="item.cod_insumo === editedItem.cod_insumo" ></v-text-field>
                <span v-else>{{item.nome}}</span>
            </td>
            <td>
                <div v-if="item.id === editedItem.id">
                  <v-icon color="red" class="mr-3" @click="close">
                    mdi-window-close
                  </v-icon>
                  <v-icon color="green"  @click="save">
                    mdi-content-save
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon color="green" class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="red" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </div>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table> -->

    </v-card-text>
    </v-card>

    <!-- modal cadastrar insumo -->
    <v-container v-if="store_ModalObra.dialog_cadastrar_insumo">
      <v-dialog
        v-model="store_ModalObra.dialog_cadastrar_insumo"
        transition="dialog-bottom-transition"
        max-width="440px"     
        persistent          
        >
        <v-card style="height: 500px">
          <ModalCadastrarInsumo
            @close="buscaInsumos"/>
        </v-card>
      </v-dialog>
    </v-container>

  </div>
</template>

<script>
import Vue from "vue";
import store_ModalObra from '../ModalObra/store_ModalObra'
import store_usuario from '../../../../store/store_usuario'

import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
  COR_BACKGROUND
} from "../../../../services/constantes";
import { formatDate, formatNumber } from "../../../../services/funcoes";
import DatePicker from "../../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../../services/API";
//import VuetifyMoney from '../../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
import ModalCadastrarInsumo from "../../Insumo/ModalInsumo/ModalCadastrarInsumo.vue";
import Draggable from 'vuedraggable';

export default {
  name: "ObraOrcamento",
  components: {
    //VuetifyMoney,
    ModalCadastrarInsumo,
    //DatePicker,    
    Draggable
  },

  data: () => ({

    store_usuario     : store_usuario,
    store_ModalObra   : store_ModalObra,    

    COR_PRINCIPAL     : COR_PRINCIPAL,
    COR_SECUNDARIA    : COR_SECUNDARIA,
    COR_SUBTITULO     : COR_SUBTITULO,
    COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
    COR_BACKGROUND    : COR_BACKGROUND,

    formatNumber      : formatNumber,
    formatDate        : formatDate,

    search: '',

    dados             : [],
    arrayItens        : [],
    arrayItensFiltro  : [],
    arrayTipos        : [],
    editedIndex       : -1,
    editedItemTipo    : 'Item',
    editedItem : {
      cod_insumo      : null,
      tipo            : null,
      codigo          : null,
      nome_insumo     : null,
      categoria       : null,
      periodo         : null,
      qtde_unit       : null,
      unidade         : null,
      valor_unitario  : null,
      total           : null,
    },
    data              : [],
    hover             : null,
    isHovering        : null,

     headers : [
      { text: "Test Value", value: "testValue" },
      { text: "", value: "actionUp" },
      { text: "", value: "actionDown" }
    ],
    item_dados : [],
    items: [
        {
          action: 'local_activity',
          title: 'Parent: Attractions',
          items: [
            { title: 'Child: Sailing',
              items: [
                  { title: 'GrandChild A - How to put this on its own row?' },
                  { title: 'GrandChild B - How to put this on its own row?'  }]
            }
          ]
        },
        {
          action: 'restaurant',
          title: 'Parent: Dining',
          active: true,
          items: [
            { title: 'Child: Breakfast & brunch', 
                items: [
                  { title: 'GrandChild 0  - How to put this on its own row?'  },
                  { title: 'GrandChild 1 - How to put this on its own row?'  }
          ]},
            { title: 'Child: New American' },
            { title: 'Child: Sushi' }
          ]
        },
        
      ]    
  }),
  
  created () {
    this.initialize();
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
    for (let i = 0; i < 20; i++) {
        this.item_dados.push({ id: i, testValue: "testValue " + i });
      }
  },

  // Create data example
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  watch: {
    //"editedItemTipo"(val) {
      //console.log('editedItemTipo', val)
      //this.editedItem.tipo == this.editedItemTipo
    //},

    "editedItem.qtde_unit"(val) {
      this.editedItem.total = null;
      if (this.editedItem.qtde_unit && this.editedItem.valor_unitario) {
        this.editedItem.total = this.editedItem.qtde_unit * this.editedItem.valor_unitario;
      }
    },
    "editedItem.valor_unitario"(val) {
      this.editedItem.total = null;
      if (this.editedItem.qtde_unit && this.editedItem.valor_unitario) {
        this.editedItem.total = this.editedItem.qtde_unit * this.editedItem.valor_unitario;
      }
    },
  },
  methods: {

    initialize () {
      console.log('initialize: ')
      this.buscaInsumos('Item');
      this.buscaEtapas();
      // this.dados = [
      // {
      //     cod_insumo        : 1,
      //     tipo            : 'Etapa',
      //     codigo          : '1',
      //     nome_insumo     : 'Serviços Preliminares',
      //     categoria       : null,
      //     periodo         : null,
      //     qtde_unit       : null,
      //     unidade         : null,
      //     valor_unitario  : null,
      //     total           : 917.50,
      //   },
      //   {
      //     cod_insumo        : 2,
      //     tipo            : 'Etapa',
      //     codigo          : '1.1',
      //     nome_insumo     : 'Limpeza de Terreno',
      //     categoria       : null,
      //     periodo         : '01/07/2024 - 20/07/2024',
      //     qtde_unit       : null,
      //     unidade         : null,
      //     valor_unitario  : null,
      //     total           : 917.50,
      //   },
      //   {
      //     cod_insumo        : 3,
      //     tipo            : 'Item',
      //     codigo          : '1.1.1',
      //     nome_insumo     : 'Servente de Pedreiro',
      //     categoria       : 'Mão Obra',
      //     periodo         : null,
      //     qtde_unit       : 4,
      //     unidade         : 'hr',
      //     valor_unitario  : 150,
      //     total           : 600,
      //   },
      //   {
      //     cod_insumo        : 4,
      //     tipo            : 'Item',
      //     codigo          : '1.1.2',
      //     nome_insumo     : 'Aluguel Caçambra',
      //     categoria       : 'Equipamento',
      //     periodo         : null,
      //     qtde_unit       : 2,
      //     unidade         : 'dia',
      //     valor_unitario  : 150,
      //     total           : 300,
      //   },
      //   {
      //     cod_insumo        : 5,
      //     tipo            : 'Item',
      //     codigo          : '1.1.3',
      //     nome_insumo     : 'Fio de Nylon',
      //     categoria       : 'Material',
      //     periodo         : null,
      //     qtde_unit       : 7,
      //     unidade         : 'un',
      //     valor_unitario  : 2.50,
      //     total           : 17.50,
      //   },
      // ]
      // this.dados = [];

      this.arrayTipos = [ 'Etapa',
                          'Item'];

    },
    async buscaInsumos() {
      this.store_ModalObra.dialog_cadastrar_insumo = false;
      const lo_Insumos = await API.get('/insumos', {});
      this.arrayItens = [];
      if (lo_Insumos.status == 200) {
        this.arrayItens   = lo_Insumos.data.result;
        this.changeTipo('Item');
        console.log('lo_Insumos--', JSON.parse(JSON.stringify(this.arrayItens)));
      }
    },
    async buscaEtapas() {
      const lo_Etapas = await API.get('/obras/orcamentos/etapas', {params: {cod_orcamento: 12}});
      console.log('lo_Etapas', lo_Etapas);
      this.dados = [];
      if (lo_Etapas.status == 200) {
        this.dados   = lo_Etapas.data.result;
        console.log('this.dados', JSON.parse(JSON.stringify(this.dados)));
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        console.log('A tecla ESC foi pressionada!');
        this.cancelIndex(this.editedIndex);
      }
      if (event.key === 'Enter') {
        console.log('A tecla ENTER foi pressionada!');
        this.focusNextField();
      }

    },

    onReorder(e) {
      let lo_Old = this.dados[e.oldIndex];
      let lo_New = this.dados[e.newIndex];
      console.log('--------------', e.oldIndex, lo_Old, ' / ', e.newIndex, lo_New)
      Vue.set(this.dados, e.newIndex, lo_Old);
      Vue.set(this.dados, e.oldIndex, lo_New);
    },

    editItem(item) {
      console.log('editItem', item);
      this.editedItemTipo = item.tipo
      this.editedIndex = this.dados.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.acao = 'E';
    },

    deleteItem (index) {
      confirm('Tem certeza que deseja retirar este item?') && this.dados.splice(index, 1);
    },

    // close () {
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   }, 300)
    // },
    cancelIndex(idx) {
      if (this.editedIndex == -1)
        return
      if (this.editedItem.acao == 'I')
        this.dados.splice(idx, 1);
      this.editedIndex = -1;
      this.editedItem = {
        acao            : null,
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
    },
    addNew(idx) {
      // O objeto que você deseja adicionar
      let newObject = {
        acao            : 'I',
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
      // Adicionando o novo objeto na posição 6 (índice 5)
      this.dados.splice(idx, 0, newObject);
      this.editedIndex  = idx;
      this.editedItem   = {...newObject};      
    },
    SaveItem(index) {
      let newObject = {
        cod_insumo      : this.dados.length+1,
        tipo            : this.editedItem.tipo,
        codigo          : '1.5',
        nome_insumo     : this.editedItem.nome_insumo,
        categoria       : this.editedItem.categoria,
        periodo         : null,
        qtde_unit       : this.editedItem.qtde_unit,
        unidade         : this.editedItem.unidade,
        valor_unitario  : this.editedItem.valor_unitario,
        total           : this.editedItem.total,
      };
      Object.assign(this.dados[index], newObject)
      this.editedIndex = -1;
      this.editedItem = {
        acao            : null,
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
      this.addNew(index+1);
      this.foco('#nome_insumo');
    },
    prepararCadastroInsumo(){
      this.store_ModalObra.acao = "I";
      this.store_ModalObra.cadastro_insumo = {}
      this.cadastrarInsumo();
    },

    cadastrarInsumo() {
      this.store_ModalObra.step = "ModalCadastrarInsumo";
      this.store_ModalObra.dialog_cadastrar_insumo = true;
    },
    foco(elem_id) {
      this.$nextTick(() => {
        let elem_focus = document.querySelector(elem_id)
        elem_focus?.focus();
      });      
    },
    async changeTipo(tipo) {
      console.log('Tipo', tipo)
      const lo_Res = this.arrayItens.filter((o) => {return o.tipo == tipo});
      this.arrayItensFiltro = [];
      this.arrayItensFiltro.push(...lo_Res);
    },
    focusNextField() {
      const form = this.$refs.form.$el;
      const focusableElements = form.querySelectorAll(
        'input, select, textarea, button, [tabindex]:not([tabindex="-1"])'
      );

      // Find the currently focused element
      const currentIndex = Array.prototype.indexOf.call(
        focusableElements,
        document.activeElement
      );

      // Focus the next element, if it exists
      if (currentIndex !== -1 && currentIndex + 1 < focusableElements.length) {
        focusableElements[currentIndex + 1].focus();
      }
    }
  },
  
};
</script>

<style scoped>

/* table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 !important;
  border: none  !important;
  background-color: #F3F3FF;
} */

/* .td-borda-right {
  border-right: 1px solid #EEEEEE;
} */

/* thead th:hover {
  background-color: #F3F3F3;
}

thead th {
  padding: 3px;
  font-weight: 600;
} */

.custom-button-start {
  width: 30px !important;
  height: 35px !important;
  z-index: 20 !important;
}

.custom-button-top {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  object-position: absolute;
  left: 0px !important;
  top: -5px !important;
  z-index: 20 !important;
}

.custom-button-bottom {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  object-position: absolute;
  left: 0px !important;
  bottom: -5px !important;
  z-index: 20 !important;
}

.custom-button-move {
  width: 25px !important;
  height: 25px !important;
  object-position: absolute;
  left: 10px !important;
  top: 0px !important;
  z-index: 20 !important;
}

.custom-button-right {
  width: 20px !important;
  height: 20px !important;
  object-position: absolute;
  right: 10px !important;
  top: 0px !important;
  z-index: 20 !important;
}

/* tr {
  height: 35px !important;
  padding: 5px !important;
} */

/* tr:hover {
  background-color: red;
} */

.alinhamento-vertical-centro {
  display: flex;
  align-items: center; 
}

.align-right {
  justify-content: right;
}

.align-center {
  display: flex;
  justify-content: center;
}

.text-title {
  color: #FFFFFF!important;  
  text-align: center!important;
}

/* ----Edits e Autocomplete  --------------------------------------------------------------------------- */

.custom-text-field .v-input__control {
  padding: 0 !important; /* Remove padding do controle de entrada */
}

.custom-text-field .v-input__slot {
  padding: 0 !important; /* Remove padding do slot de entrada */
}

.custom-text-field .v-text-field__slot {
  padding: 0 !important; /* Remove padding do slot específico do v-text-field */
}

.custom-text-field input {
  padding: 0 !important; /* Remove padding do campo de entrada */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-text-field .v-label {
  padding: 0 !important; /* Remove padding do label */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-text-field .v-messages__wrapper {
  padding: 0 !important; /* Remove padding das mensagens */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-autocomplete .v-input__control {
  min-height: 30px; /* Ajuste a altura mínima conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-input__slot {
  height: 30px; /* Ajuste a altura do slot conforme necessário */
  padding: 0px; /* Ajuste o padding conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-select__selections {
  line-height: 30px; /* Ajuste a altura da linha conforme necessário */
}

.custom-autocomplete input {
  height: 30px; /* Ajuste a altura do input conforme necessário */
  line-height: 30px; /* Ajuste a altura da linha do input conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-label {
  top: -20px; /* Ajuste a posição do label conforme necessário */
  font-size: 12px; /* Ajuste o tamanho da fonte do label conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-select__selections > div {
  margin-top: -5px; /* Ajuste a margem superior conforme necessário */
  padding: 0px;
}
/* ---- FIM Edits e Autocomplete  --------------------------------------------------------------------------- */

/* .text-nome:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--COR_SUBTITULO);
} */

/* ::v-deep .theme--light.v-input input, .theme--light.v-input textarea .v-input .v-input__slot div.v-text-field__slot fieldset .input input  {          */
  /* border: 1px solid rgba(255, 0, 0, 0.2) !important; */
  /* background-color: rgba(255, 0, 0, 0.2) !important; */
  /* margin-left: -9px !important; */
  /* height: 30px !important;; */
  /* width: 100% !important;; */
/* } */

/* ::v-deep .theme--light.v-input input {
  border: 0px solid rgba(255, 0, 0, 0.2) !important;
  background-color: rgba(0, 255, 0, 0.1) !important;
} */

/*
::v-deep .v-application--is-ltr .v-text-field--reverse input {
  padding: 0px !important;
} */

/* .no-margin td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
} */

/*
.no-border {
  border: none !important;
}

.no-border thead th td {
  border: none !important;
} 
*/

.nivel-1px {
  width: 100%!important;
}

.nivel-100px {
  width: 1px!important;
}

.nivel-1-left {
  margin-left : 0px!important;
}
.nivel-1-background {
  align-items: center; 
  height: 100%;  
  width: 100%;
  background-color: rgba(170, 220, 180, 0.5);
  font-weight: 600;
  color: var(--COR_PRINCIPAL);
}

.nivel-2-left {
  margin-left : 30px!important;
}
.nivel-2-background {
  align-items: center; 
  height: 100%;  
  width: 100%;
  background-color: rgba(170, 200, 180, 0.4);
  color: rgba(0, 0, 0, 0.8);;
}

.nivel-3-left {
  margin-left : 60px!important;
}
.nivel-3-background {
  align-items: center; 
  height: 100%;
  width: 100%;
  background-color: rgba(230, 230, 230, 0.6);
}

</style>
