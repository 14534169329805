import Vue from "vue";
import { API } from "../../../../services/API"

function initialState() {
  return {
    acao: "I", // E=Edicao, I=Inclusão C=Consulta
    dados: [],

    cadastro_obraAux: {}, //Dados a serem alterados no cadastro/edicao do endereco da obra
    cadastro_cobrancaAux: {}, //Dados a serem alterados no cadastro/edicao do endereco de cobrancao
    consulta_depositoAux: {}, //Dados a serem alterados no cadastro/edicao do endereco do deposito
    currentTransition: 'next',


    dialog_consultaObra: false,
    dialog_cadastrar_endereco: false,
    dialog_cadastrar_insumo: false,


    tipo: "",
    JSON_FORM: {},
    step: "",
    obra_selecionado: {},
  }
}

var vm_store_ModalObra = new Vue({
  data() {
    return initialState();
  },

  methods: {
    resetData() {
      Object.assign(this.$data, initialState());
    },

    async EnderecoPost(p_params) {
      const resp = await API.post(`/obras/enderecos`, p_params);
      return resp;
    },
    
    async EnderecoPut(p_params) {
      const resp = await API.put(`/obras/enderecos/${p_params.cod_endereco}`, p_params);
      return resp;
    },
  }
})

export default vm_store_ModalObra