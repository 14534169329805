<template>
  <div id="ModalCadastrarEndereco" class="pa-0">
    <v-card class="container pa-0">
      <v-card-title
        v-bind:style="{ 'background-color': COR_PRINCIPAL }"
        class="justify-space-between py-2 px-3"
      >
        <span class="text-white title-page body-1">Cadastro de Endereço</span>
        <v-btn
          icon
          dark
          color="#F2F6F7"
          @click="cancelar()"
        >
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-2 pb-1">
        <v-container>
          <v-form v-model="valid" ref="form">
            <v-row>
              <!-- CEP -->
              <v-col cols="11" style="height: 30px" class="px-0">
                <v-text-field
                  v-model="enderecoAtual.cep"
                  class="mt-n2 obrigatorio"
                  light
                  background-color="#FFF"
                  label="Cep"
                  placeholder="xxxxx-xxx"
                  maxlength="9"
                  filled
                  outlined
                  required
                  dense
                  autofocus
                  v-mask="MASK_CEP"
                  :rules="cepRules"
                ></v-text-field>
              </v-col>
              <!-- Botão Consulta CEP -->
              <v-col cols="1" class="px-0">
                <v-icon
                  class="btn-icon-plus mr-1 ml-2"
                  color="accent-4"
                  @click="buscaEndereco(enderecoAtual.cep)"
                  >mdi-magnify
                </v-icon>
              </v-col>

              <!-- Quadra -->
              <v-col cols="5" class="pa-0 pt-4 mr-auto">
                <v-text-field
                  v-model="enderecoAtual.quadra"
                  class="mb-0 "
                  light
                  background-color="#FFF"
                  label="Quadra"
                  placeholder="Quadra"
                  filled
                  outlined 
                  required
                  dense
                />
              </v-col>

              <!-- Lote -->
              <v-col cols="5" class="pa-0 pt-4">
                <v-text-field
                  v-model="enderecoAtual.lote"
                  class="mb-0 "
                  light
                  background-color="#FFF"
                  label="Lote"
                  placeholder="Lote"
                  filled
                  outlined
                  required
                  dense
                />
              </v-col>

              <v-col cols="12" class="px-0 py-0 mt-n1">
                <!-- Logradouro -->
                <v-text-field
                  v-model="enderecoAtual.logradouro"
                  class="mb-1 obrigatorio"
                  light
                  background-color="#FFF"
                  label="Endereço"
                  placeholder="Endereço"
                  maxlength="60"
                  filled
                  outlined
                  required
                  dense
                  :rules="enderecoRules"
                />

                <v-text-field
                  v-model="enderecoAtual.numero"
                  class="mt-n2 mb-1"
                  light
                  background-color="#FFF"
                  label="Número"
                  placeholder="Número"
                  filled
                  outlined
                  dense
                />

                <v-text-field
                  v-model="enderecoAtual.bairro"
                  class="mt-n2"
                  light
                  background-color="#FFF"
                  label="Bairro"
                  placeholder="Bairro"
                  filled
                  outlined
                  dense
                />

                <v-autocomplete
                  v-model="enderecoObraSelecionado"
                  :loading="loading"
                  :search-input.sync="search"
                  :items="arrayMunicipios"
                  no-filter
                  hide-selected
                  auto-select-first
                  class="mt-n1 obrigatorio"
                  clearable
                  label="Município"
                  item-text="nome_exibicao"
                  item-value="codigo"
                  filled
                  outlined
                  dense
                  background-color="#FFF"
                  required
                  :rules="municipioRules"
                  return-object
                />

                <v-text-field
                  v-model="enderecoAtual.complemento"
                  class="mt-n1"
                  light
                  background-color="#FFF"
                  label="Complemento"
                  placeholder="Complemento"
                  filled
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
          <v-footer
            color="transparent"
            elevation="0"
            class="footer justify-center py-0"
          >
            <v-btn
              color="primary"
              class="mr-4 caption font-weight-medium"
              text
              @click="cancelar()"
              id="btn-cancelar"
              ref="btn-cancelar"
            >
              Cancelar
            </v-btn>
            
            <v-btn
              :disabled="!valid"
              :loading="loading"
              @click="validate()"
              class="btn white--text caption font-weight-medium"
              color="primary accent-4"
            >
              Salvar
            </v-btn>
          </v-footer>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store_ModalObra from "./store_ModalObra";
import store_site from "./../../../../store/store_site";
import { API } from "../../../../services/API";
import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CEP,
} from "../../../../services/constantes";
import store_Obra from "./../store_Obra";
import axios from "axios";

export default {
  name: "ModalCadastrarEndereco",
  data() {
    return {
      store_site        : store_site,
      store_ModalObra   : store_ModalObra,
      store_Obra        : store_Obra,
      MASK_CEP          : MASK_CEP,
      array_cep         : {},
      loading           : false,
      search            : null,
      enderecoObraSelecionado: null,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      arrayMunicipios   : [],

      valid: true,
      cepRules: [
        (value) => !!value || "O cep é obrigatório",  
        (value) => (value && value.length == 9) || "O cep não é válido",
      ],
      enderecoRules: [
        (value) => !!value || "O endereço é obrigatório",
        (value) =>
          (value && value.length <= 59) ||
          "O endereço deve ter no máximo 60 caracteres",
      ],
      municipioRules: [(value) => !!value || "O municipio é obrigatório"],
    };
  },

  mounted() {
    if(this.store_ModalObra.tipo == "Endereco da Obra"){
      if(this.store_ModalObra.cadastro_obraAux.cidade && this.store_ModalObra.cadastro_obraAux.uf) {
      this.arrayMunicipios = [{ codigo        : null,
                                nome          : this.store_ModalObra.cadastro_obraAux.cidade, 
                                uf            : this.store_ModalObra.cadastro_obraAux.uf,
                                nome_exibicao : this.store_ModalObra.cadastro_obraAux.cidade + " - " + this.store_ModalObra.cadastro_obraAux.uf}];
      this.enderecoObraSelecionado = {  codigo        : null,
                                        nome          : this.store_ModalObra.cadastro_obraAux.cidade, 
                                        uf            : this.store_ModalObra.cadastro_obraAux.uf,
                                        nome_exibicao : this.store_ModalObra.cadastro_obraAux.cidade + " - " + this.store_ModalObra.cadastro_obraAux.uf
                                        };
      } 
    } else {
      if (this.store_ModalObra.cadastro_cobrancaAux.cidade && this.store_ModalObra.cadastro_cobrancaAux.uf) { 
      this.arrayMunicipios = [{ codigo        : null,
                                nome          : this.store_ModalObra.cadastro_cobrancaAux.cidade, 
                                uf            : this.store_ModalObra.cadastro_cobrancaAux.uf,
                                nome_exibicao : this.store_ModalObra.cadastro_cobrancaAux.cidade + " - " + this.store_ModalObra.cadastro_cobrancaAux.uf}];
      this.enderecoObraSelecionado = {  codigo        : null,
                                        nome          : this.store_ModalObra.cadastro_cobrancaAux.cidade, 
                                        uf            : this.store_ModalObra.cadastro_cobrancaAux.uf,
                                        nome_exibicao : this.store_ModalObra.cadastro_cobrancaAux.cidade + " - " + this.store_ModalObra.cadastro_cobrancaAux.uf
                                        };
      }
    }
  },

  computed: {
    enderecoAtual() {
      switch (this.store_ModalObra.tipo) {
        case "Endereco da Obra":
          return (this.store_ModalObra.cadastro_obraAux);
        case "Endereco de Cobranca":
          return (this.store_ModalObra.cadastro_cobrancaAux); 
        default:
          return {}; // Retorna um objeto vazio como fallback
      }
    },
  },

  watch: {
    search(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },
  },

  methods: {
    cancelar() {
      this.store_ModalObra.dialog_cadastrar_endereco = false;
    },

    updateEnderecoAtual(updatedEndereco) {
      switch (this.store_ModalObra.tipo) {
        case "Endereco da Obra":
          this.store_ModalObra.cadastro_obraAux = updatedEndereco;
          break;
        case "Endereco de Cobranca":
          this.store_ModalObra.cadastro_cobrancaAux = updatedEndereco;
          break;
        default:
          break;
      }
      console.log("updatedEndereco", this.enderecoAtual);
    },

    querySearch(query){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params);
      this.loading = false
    },

    busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
      const ls_Rel_Municipios = await API.get(`municipio`, val);
      if (Array.isArray(ls_Rel_Municipios.data.result)) {
        ls_Rel_Municipios.data.result.map(el => el.nome_exibicao = `${el.nome} - ${el.uf}`)
        this.arrayMunicipios = ls_Rel_Municipios.data.result;
      }
      }, 600);
    },

    // Busca o endereço pelo CEP
    async buscaEndereco(val) {
      if (val && val.length == 9) {
        const ls_Rel_Cep = await axios.get(
          `https://viacep.com.br/ws/${val}/json/`
        );
        if (!ls_Rel_Cep.data.erro) {
          this.array_cep = ls_Rel_Cep.data;
          this.enderecoAtual.logradouro = this.array_cep.logradouro;
          this.enderecoAtual.bairro = this.array_cep.bairro;

          //const ls_Rel_Municipios = await API.get(`municipio`, {params: { cod_ibge: this.array_cep.ibge }});

          //if (ls_Rel_Municipios.data.result.length > 0) {
            this.arrayMunicipios = [ { codigo         : this.array_cep.ibge,
                                        nome          : this.array_cep.localidade,
                                        uf            : this.array_cep.uf, 
                                        nome_exibicao : this.array_cep.localidade + ' - ' + this.array_cep.uf}]
            this.enderecoObraSelecionado = {...this.arrayMunicipios[0]};
          //}
      }
    }
  },

    // Validação do formulário 
    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        let ls_Rel = null;
        this.enderecoAtual.cidade = this.enderecoObraSelecionado.nome;
        this.enderecoAtual.uf = this.enderecoObraSelecionado.uf;
        var lo_params = {
          ...JSON.parse(JSON.stringify(this.enderecoAtual)),
          tipo: this.store_ModalObra.tipo,
          cod_obra: this.store_Obra.obra_edicao.cod_obra,
        };
        if(this.store_ModalObra.acao == 'I'){
          ls_Rel = await this.store_ModalObra.EnderecoPost(lo_params);
        } else {
          console.log("lo_params", lo_params);
          ls_Rel = await this.store_ModalObra.EnderecoPut(lo_params);
        }
        if (ls_Rel.status == 200) {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result.trim();
          this.store_site.alert = true;
        } else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result.trim();
          this.store_site.alert = true;
        }
        this.store_ModalObra.dialog_cadastrar_endereco = false;
        this.arrayMunicipios = [{ nome: "" }]
        if (lo_params.cod_obra) {
          const lo_Res = await this.store_Obra.ObraGet(lo_params);
          if (lo_Res) {
            this.store_Obra.obra_edicao = { ...lo_Res };
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.container {
  background: #f2f6f7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
}
</style>
